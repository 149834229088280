<template>
  <div class="main">
    <a-row  align="middle" type="flex" justify="space-between">
      <a-col :span="8">
        <a-page-header title="姓名管理" sub-title="按姓氏排列，优先显示推荐的姓名" />
      </a-col>
      <a-space size="middle">
        <a-radio-group button-style="solid" :default-value="sex" @change="selectSex">
          <a-radio-button :value="2">
            全部
          </a-radio-button>
          <a-radio-button :value="0">
            男
          </a-radio-button>
          <a-radio-button :value="1">
            女
          </a-radio-button>
        </a-radio-group>
        <a-input-search placeholder="输入姓氏或完整姓名搜索" style="width: 240px;" allowClear v-model="keyword" @search="getListData(1)" />
        <a-button type="primary" icon="plus" @click="showAddModel">添加</a-button>
      </a-space>
    </a-row>
    <!-- 菜单列表 -->
    <a-spin tip="Loading..." :spinning="listLoading">
      <a-table :columns="tableTitle" :pagination="listPagination" :data-source="data" size="middle" :rowKey="record=>record.id">
        <template slot="sex" slot-scope="text, record">
          <a-badge v-if="record.sex == 0" color="blue" text="男" />
          <a-badge v-else color="pink" text="女" />
        </template>
        <span slot="top" slot-scope="top,record">
          <a-switch checked-children="是" un-checked-children="否" :checked = "top == true" :loading="record.loadding" @change = "switchStatus($event,record.id)"/>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm title="删除后无法恢复,确定?" @confirm="del(record.id)" okText="是" cancelText="否">
            <a-button size="small" icon="delete">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </a-spin>
    <!-- 菜单列表 End -->
    <!-- 新增model -->
    <a-drawer width="540" placement="right" maskClosable :visible="addModel" @close="hideModel" title="新增姓名" destroyOnClose>
      <a-form :form="addform" @submit="postForm" selfUpdate>
      <a-spin tip="Loading..." :spinning="postLoading">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="姓氏" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-input allowClear placeholder="请输入姓氏" v-decorator="['xing', { rules: [{ required: true, message: '请输入姓氏!' }] }]" />
            </a-form-item>
            <a-form-item label="姓别" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-radio-group v-decorator="['sex', { rules: [{ required: true, message: '请选择性别!' ,type:'number',min:0}] , initialValue:0}]">
                <a-radio :value="0">男</a-radio>
                <a-radio :value="1">女</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="姓名" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
              <a-textarea allowClear placeholder="多个姓名时使用;符号间隔，如张三;李四" :autoSize="{ minRows: 4}"  v-decorator="['ming', { rules: [{ required: true, message: '请输入内容!' }] }]" />
              <a-alert
                message="每次最多添加100个姓名，如有重复的姓名则不添加该姓名，添加成功的姓名自动优先展示！"
                showIcon
                closable
                type="info"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-spin>
      <div class="footer">
        <a-button :style="{marginRight: '8px'}" @click="hideModel">取消</a-button>
        <a-button type="primary" html-type="submit" :loading="postLoading">发布</a-button>
      </div>
      </a-form>
    </a-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableTitle: [
        { title: "id", dataIndex: "id", key: "id" },
        { title: "姓名", dataIndex: "name", key: "name"},
        { title: "性别", dataIndex: "sex", key: "sex", scopedSlots: { customRender: "sex"}},
        { title: "名字个数", dataIndex: "geshu", key: "geshu"},
        { title: "优先", dataIndex: "top", key: "top", scopedSlots: { customRender: "top" }, width:'120px'},
        { title: "操作", key: "action", scopedSlots: { customRender: "action" },width:"100px"}
      ],
      keyword: '',
      sex: 2,
      data: [],
      listLoading: false, //列表loadding
      listPagination: {
        onChange: page => {
          this.listPage = page;
          this.getListData(page);
        },
        showQuickJumper: true,
        total: 0,
        current: 1,
        pageSize: 15
      },
      addModel:false,
      addform: this.$form.createForm(this, { name: "add_form" }), //编辑表单的初始化
      postLoading: false,
    };
  },
  mounted() {
    this.getListData(1);
  },
  methods: {
    getListData(pages) {
      this.listLoading = true;
      this.$http
        .get("/admin/name/mingList",{params: {page: pages, keywords: this.keyword, sex: this.sex}})
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.listPagination.current = pages
          this.data = res.data.result.data
          this.listPagination.total = res.data.result.count
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    //性别切换
    selectSex(e){
      this.sex = e.target.value
      this.getListData(1)
    },
    //推荐开关 切换
    switchStatus(event,key){
      let index = -1
      for(let i = 0;i < this.data.length; i++){
        if(this.data[i].id === key)index = i
      }
      this.data[index].loadding = true
      this.$http
        .get("/admin/name/switchTop",{params: {id: key, top: +event}})
        .then(() => {
          this.data[index].loadding = false;
          this.data[index].top = event;
        })
        .catch(() => {
          //请求失败后的处理函数
          this.data[index].loadding = false;  
        });
    },
    showAddModel(){
      this.addModel = true
    },
    hideModel(){
      this.addModel = false
    },
    postForm(e) {
      e.preventDefault();
      this.addform.validateFields((err, values) => {
        if (!err) {
          this.postLoading = true;
          let postData = new FormData();
          Object.keys(values).forEach(key => {
            postData.append(key, values[key]);
          });
          this.$http
            .post("/admin/name/addMing", postData)
            .then(res => {
              //请求成功后的处理函数
              this.postLoading = false;
              this.$notification["success"]({
                message: "系统提示",
                description: res.data.msg,
                duration: 5.5,
                key: 2
              });
              this.addModel = false
              this.getListData(this.listPagination.current)
            })
            .catch(() => {
              //请求失败后的处理函数
              this.postLoading = false;
            });
        }
      });
    },
    del(id){
      this.$http
        .get("/admin/name/del",{params: {id: id}})
        .then(() => {
          this.getListData(this.listPagination.current)
        })
    },
  }
};
</script>
<style lang="scss" scoped>
  .footer {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    text-align: right;
  }
</style>